const fs = require('fs');
const path = require('path');

const root = "."
const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.webp', '.tiff'];

// Function to iterate through files in a given folder
const iterateFiles = (folderPath) => {
    const files = fs.readdirSync(folderPath)
    const resultList = [];

    files.forEach((file) => {
        const filePath = path.join(folderPath, file);
        const stats = fs.statSync(filePath);
        if (stats.isFile() && imageExtensions.includes(path.extname(filePath))) {
            resultList.push({path: path.relative(root, filePath), credit: undefined})
        } else if (stats.isDirectory()) {
            resultList.push(iterateFiles(filePath));
        }
    });

    return resultList;
};

function reload() {
    const files = iterateFiles(root);
    let inventory = JSON.parse(fs.readFileSync('./inventory.json', 'utf-8'));
    for (const file of files) {
        if (inventory[file.path] === undefined) {
            inventory[file.path] = file;
        }
    }
    // eslint-disable-next-line no-unused-vars
    for (const [_, item] of Object.entries(inventory)) {
        item.content = undefined
    }
    // inventory = Object.fromEntries(Object.entries(inventory).sort())
    const json_content = JSON.stringify(inventory, null, 2);

    for (const [, item] of Object.entries(inventory)) {
        item.content = '¤' + root + "/" + item.path + '¤'
    }
    let js_content = JSON.stringify(inventory, null, 2);

    js_content = "const inventory = "
        + js_content
        + ";\nmodule.exports = {inventory : inventory};";
    js_content = js_content.split(/¤"/).join('")').split(/"¤/).join('require("')

    fs.writeFileSync('./inventory.js', js_content, 'utf-8');
    fs.writeFileSync('./inventory.json', json_content, 'utf-8');
}

reload()