const inventory = {
  "diapason.jpeg": {
    "path": "diapason.jpeg",
    "credit": {
      "text": "franceinfo",
      "url": "https://france3-regions.francetvinfo.fr/bretagne/ille-et-vilaine/rennes/rennes-campus-universitaire-beaulieu-elargit-son-offre-soins-etudes-aux-maladies-chroniques-1756075.html"
    },
    "content": require("./diapason.jpeg")
  },
  "duguesclin.jpeg": {
    "path": "duguesclin.jpeg",
    "content": require("./duguesclin.jpeg")
  },
  "duguesclin2.jpeg": {
    "path": "duguesclin2.jpeg",
    "content": require("./duguesclin2.jpeg")
  },
  "experimentarium.jpeg": {
    "path": "experimentarium.jpeg",
    "content": require("./experimentarium.jpeg")
  },
  "img.png": {
    "path": "img.png",
    "content": require("./img.png")
  },
  "istic.jpg": {
    "path": "istic.jpg",
    "credit": {
      "text": "wikimedia",
      "url": "https://fr.m.wikipedia.org/wiki/Fichier:IFSIC.jpg"
    },
    "content": require("./istic.jpg")
  },
  "logo.png": {
    "path": "logo.png",
    "content": require("./logo.png")
  },
  "logo64.png": {
    "path": "logo64.png",
    "content": require("./logo64.png")
  },
  "mathc2_2023.jpg": {
    "path": "mathc2_2023.jpg",
    "content": require("./mathc2_2023.jpg")
  },
  "mathc2_2023_1080p.jpg": {
    "path": "mathc2_2023_1080p.jpg",
    "content": require("./mathc2_2023_1080p.jpg")
  },
  "mathc2_2023_540p.jpg": {
    "path": "mathc2_2023_540p.jpg",
    "content": require("./mathc2_2023_540p.jpg")
  },
  "mathc2_2024_1.jpg": {
    "path": "mathc2_2024_1.jpg",
    "content": require("./mathc2_2024_1.jpg")
  },
  "mathc2_2024_2.jpg": {
    "path": "mathc2_2024_2.jpg",
    "content": require("./mathc2_2024_2.jpg")
  },
  "tfjm.png": {
    "path": "tfjm.png",
    "content": require("./tfjm.png")
  },
  "profile_picture_0.jpg": {
    "path": "profile_picture_0.jpg",
    "width": "175%",
    "margin": "125px 25px 0 0",
    "content": require("./profile_picture_0.jpg")
  },
  "profile_picture_1.jpg": {
    "path": "profile_picture_1.jpg",
    "width": "175%",
    "margin": "100px 40px 0 0",
    "content": require("./profile_picture_1.jpg")
  },
  "profile_picture_2.jpg": {
    "path": "profile_picture_2.jpg",
    "width": "175%",
    "margin": "85px 35px 0 0",
    "content": require("./profile_picture_2.jpg")
  },
  "profile_picture_3.jpg": {
    "path": "profile_picture_3.jpg",
    "width": "175%",
    "margin": "110px -5px 0 0",
    "content": require("./profile_picture_3.jpg")
  },
  "profile_picture_4.jpg": {
    "path": "profile_picture_4.jpg",
    "width": "190%",
    "margin": "195px -5px 0 0",
    "content": require("./profile_picture_4.jpg")
  }
};
module.exports = {inventory : inventory};