import React from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import NavBar from './components/NavBar';
import './App.css';
import config from "./pages/Config";

function getPageComponent(js_path, params) {
    const PageComponent = require(__dirname + "/" + js_path).default;
    return PageComponent(params);
}

function RoutesComp(params) {
    return (<Routes>
        {config.pages.map((page, id) => {
            return (!config.in_production || page.released) && <Route
                path={page.url_path}
                element={getPageComponent(page.js_path, params)}/>
        })}
    </Routes>);
}

function SubApp() {
    return (<div className="App">
        <NavBar pathname={useLocation().pathname}/>
        <RoutesComp pathname={useLocation().pathname}/>
    </div>)
}

function App() {
    return (<Router>
        <SubApp/>
    </Router>);
}

export default App;
