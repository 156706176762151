export const links = [
    {
        url: "https://www.linkedin.com/in/mewen-crespo/?originalSubdomain=fr",
        title: "LinkedIn"
    }, {
        url: "https://irmar.univ-rennes.fr/interlocuteurs/mewen-crespo",
        title: "Laboratory"
    }, {
        url: "https://cv.hal.science/mewen-crespo",
        title: "HAL"
    }, {
    url: "https://scholar.google.com/citations?user=7hdAPAUAAAAJ&hl=fr",
        title: "Google Scholar"
    }, {
    url: "https://www.researchgate.net/profile/Mewen-Crespo",
        title: "Research Gate"
    }
];

export default links;