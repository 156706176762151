import React from 'react';
import './LanguageList.css';

const LanguageList = ({ languages }) => {
    return (
        <div className="language-list">
            {languages.map((language, index) => (
                <div className="language" key={index}>
                    <img src={language.logo} alt={`${language.name} Logo`} className="logo" />
                    <span className="language-name">{language.name}</span>
                    <div className="progress-bar">
                        <div className="progress" style={{ width: `${language.proficiency}%` }}></div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default LanguageList;
