import React from 'react';
import PublicationCard from "../components/PublicationCard";
import data from "./data/Publications";

const CVComponent = () => {
    return (
        <html>
        <head>
            <link
                href="//cdnjs.cloudflare.com/ajax/libs/KaTeX/0.9.0/katex.min.css"
                rel="stylesheet"
            />
            <title>Mewen Crespo - Publications</title>
        </head>
        <body className="publications-page">
        <h1>Publications</h1>
        <div className="publications-list">
            {data.map((pub, index) => (
                <PublicationCard
                    key={index}
                    title={pub.title}
                    date={pub.date}
                    type={pub.type}
                    url={pub.url}
                    externalIds={pub.externalIds}
                    contributors={pub.contributors}
                    abstract={pub.abstract}
                />
            ))}
        </div>
        </body>
        </html>
    );
};

export default CVComponent;