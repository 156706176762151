import React from 'react';
import {Link} from 'react-router-dom';
import './NavBar.css';
import config from '../pages/Config';

function NavBar({hash, pathname, search}) {
    return (<nav className="navbar">
        <ul className="navbar-links">
            {config.pages.map((page, id) => {
                return (!config.in_production || page.released)
                    && <li><Link
                        to={page.url_path}
                        className={pathname === page.url_path ? "active" : ""}>{page.name}</Link></li>
            })}
        </ul>
    </nav>);
}

export default NavBar;
