import {isObject} from "pdfmake/src/helpers";

export function tryIndex(obj : {}, ...indexes:[]) {
    for (const indexesArray of indexes) {
        let indexed = obj
        for (const index of indexesArray) {
            if (!isObject(indexed) || indexed[index] === undefined) {
                indexed = undefined;
                break
            }
            indexed = indexes[index];
        }
    }
    return undefined;
}