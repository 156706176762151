import React from "react";

// noinspection SpellCheckingInspection
const items = [{
    title: <div>
        <center>Maths Bachelor's<br/><small>2017 - 2020</small></center>
    </div>,
    cardTitle: "Bachelor's Degree in Mathematics (with high honors)",
    cardSubtitle: "University of Rennes",
    cardDetailedText: <div>
        <ul>
            <li>First year:
                <ul>
                    <li>65% Pure mathematics</li>
                    <li>20% of Computer Science</li>
                    <li>15% Physics</li>
                </ul>
            </li>
            <li>Second year:
                <ul>
                    <li>75% Pure mathematics</li>
                    <li>20% of Computer Science</li>
                    <li>5% Physics</li>
                </ul>
            </li>
            <li>Third year:
                <br/>
                Only fundamental mathematics. The topics covered include:
                <ul>
                    <li>Lebesgue Integration</li>
                    <li>Differential Equations</li>
                    <li>Group Theory</li>
                    <li>Topology</li>
                    <li>Linear Algebra and Differential Calculus</li>
                    <li>Probability Theory</li>
                    <li>Holomorphic Theory</li>
                </ul>
            </li>
        </ul>
    </div>
},

    {
        title: <div>
            <center>CS Bachelor's<br/><small>2019 - 2020</small></center>
        </div>,
        cardTitle: "Bachelor's Degree in Computer Science (with highest honors, vis-major)",
        cardSubtitle: "University of Rennes",
        cardDetailedText: <div>
            This degree was taken in parallel with the mathematics degree,
            the reason being the absence of computer science in the third year
            of the mathematics degree. Since it is a separate course
            (with no special arrangements), it leads to a second bachelor's degree,
            independent of the first. The topics covered include:
            <ul>
                <li>Graph Algorithms</li>
                <li>Algorithmic Theory on Data Structures</li>
                <li>Object Oriented Programming</li>
                <li>Formal Languages</li>
                <li>Java</li>
                <li>Unix/C</li>
                <li>Compilation</li>
                <li>I/O Interfaces</li>
                <li>Program Checking</li>
                <li>Operating Systems</li>
            </ul>
        </div>
    },

    {
        title: <div>
            <center>CS Bachelor's Internship<br/><small>Summer 2020 (2 months and 10 days)</small></center>
        </div>,
        cardTitle: "Internship - Multidimensional regression",
        cardSubtitle: "Inscoper, Rennes",
        cardDetailedText: <div>
            <a href="https://www.inscoper.com/">
                <img src="https://www.inscoper.com/wp-content/uploads/2023/03/inscoper-logo-corporate.jpg" width="100"
                     height="15" alt="INSCOPER" id="logo" data-height-percentage="54" data-actual-width="200"
                     data-actual-height="33"/>
            </a> is a startup specialized in the development of a device allowing for a centralization of
            previously independent microscopy tool. This allows for a centralized control through a custom software
            and an easier setup as all drivers are installed and devices controlled automatically by the software.
            The goal of this internship was the development of a machine learning base algorithm allowing for an
            automatic estimation of the execution time of a given sequence of operations (optic changes, movement
            of the tray, image acquisition, etc).
        </div>
    },

    {
        title: <div>
            <center>Master Year 1<br/><small>2020 - 2021</small></center>
        </div>,
        cardTitle: "Master's Degree in Fundamental Mathematics - Year One of Two",
        cardSubtitle: "University of Rennes",
        cardDetailedText: <div>
            This first year of a two-year-long master's degree covered more advanced topics in the field of
            abstract mathematics. The topics covered include:
            <ul>
                <li>Differential Geometry</li>
                <li>Complex Analysis and Special Functions</li>
                <li>Hilbert Space Analysis</li>
                <li>Advanced Group Theory and Geometry</li>
                <li>Advanced Non-linear Algebra</li>
                <li>Distribution Theory</li>
                <li>Commutative Algebra and Algebraic Geometry</li>
                <li>Algebraic Topology</li>
                <li>Markov Chains and Martingales</li>
            </ul>
        </div>
    },

    {
        title: <div>
            <center>MITx 8.05 - Quantum Physics<br/><small>2020 - 2021</small></center>
        </div>,
        cardTitle: "Online Open Course dispensed by the MIT on Quantum Physics",
        cardSubtitle: "Online (Edx)",
        cardDetailedText: <div>
            This online course of approximately 15 to 20 hours per week was taken in parallel to the first year of the
            Master's degree in fundamental mathematics and led, once the online exam was passed, to a certificate.
            This intermediate-level course on quantum mechanics spanned several topics, including but not limited to:
            <ul>
                <li>Spin and Lie Algebra</li>
                <li>Bracket notation and position/momentum representation (Fourier transform)</li>
                <li>Heisenberg picture</li>
                <li>Incertitude principle</li>
                <li>Coherent and squeeze states</li>
            </ul>
        </div>
    },

    {
        title: <div>
            <center>Master Y1 Internship<br/><small>Summer 2021 (3 months and 13 days)</small></center>
        </div>,
        cardTitle: "Internship - Image Segmentation",
        cardSubtitle: "I.G.D.R. / C.N.R.S, Rennes",
        cardDetailedText: <div>
            The Institut de Génétique et Développement de Rennes&nbsp;
            <a href="https://igdr.univ-rennes.fr" title="IGDR webpage">
                <img src="https://igdr.univ-rennes.fr/sites/igdr.univ-rennes.fr/files/logo-IGDR.png"
                     alt="IGDR"
                     width="30"
                     height="15"/>
            </a>
            is the a laboratory of biology part of the Centre National de la Recherche Scientifique&nbsp;
            <a href="https://www.cnrs.fr/fr" title="CNRS webpage">
                <img src="https://www.cnrs.fr/sites/default/files/logo/logo.svg"
                     alt="CNRS"
                     width="15"
                     height="15"/>
            </a> and specialized in the study of genetics. In this laboratory, the Cell Division Reverse Engineering
            (<a href="https://igdr.univ-rennes.fr/equipe-cedre" title="CEDRE webpage">CEDRE</a>)
            is focussing on the study of cell division, and in particular the astral microtubules (the "arms" pulling
            the DNA). This internship was carried out at the end of the
            first year of the Master's degree. The goal was to develop a machine-learning-based algorithm in order to
            automatically denoise the input images, segment-out the microtubule from them and compute the mean
            curvature of the astral microtubules. As there was practically no data (only 4/5 pictures), a data
            generation pipeline was necessary. This was done by simulating the microtubules and adding noise to the
            resulting images.
        </div>
    },

    // TODO PhD (+ Master Y2 Internship)


    {
        title: <div>
            <center>Cryptography Master's<br/><small>2021 - 2022</small></center>
        </div>,
        cardTitle: "Master's Degree in Cryptography (with highest honors)",
        cardSubtitle: "University of Rennes",
        cardDetailedText: <div>
            This degree was taken in parallel with the second year of the Master's Degree in Fundamental
            Mathematics, further strengthening skills in computer science. Since it was technically classified as
            a mathematical degree, the university did not provide a distinct certificate. The topics covered
            include:
            <ul>
                <li>Elliptic Curves</li>
                <li>Euclidean Lattices</li>
                <li>Algorithmic Number Theory</li>
                <li>Quantum Cryptography</li>
                <li>Error Correction Codes</li>
            </ul>
        </div>
    },


    {
        title: <div>
            <center>Math Master's<br/><small>2021 - 2022</small></center>
        </div>,
        cardTitle: "Master's Degree in Fundamental Mathematics (with highest honors)",
        cardSubtitle: "University of Rennes",
        cardDetailedText: <div>
            This second and final year of a two-year-long master's degree covered, in depth, several advanced topics
            in the field of algebra and geometry. The topics covered include, but are not limited to:
            <ul>
                <li>Differential Galois Theory</li>
                <li>Toric Geometry</li>
                <li>Lie Groups and Algebra</li>
                <li>Riemann Surfaces and Complex Geometry</li>
                <li>P-adic Numbers and Dynamic Systems</li>
            </ul>
        </div>
    },

    {
        title: <div>
            <center>Ph.D. (Current Position)<br/><small>2022 - 2025</small></center>
        </div>,
        cardTitle: "Ph.D. - Geometry of Defective Media",
        cardSubtitle: "University of Rennes",
        cardDetailedText: <div>
            This Ph.D. thesis, supervised by
            &nbsp;<a
            about={"Website of Pr. Le Marrec"}
            href={"https://irmar.univ-rennes.fr/interlocuteurs/loic-le-marrec"}>Pr. Loïc Le Marrec</a>&nbsp;
            and
            &nbsp;<a
            about={"Website of Dr. Casale"}
            href={"https://perso.univ-rennes1.fr/guy.casale/CV.html"}>Dr. Guy Casale</a>,
            aims at using differential geometry to improve current continuum mechanics' models for defective media.
            Indeed, media with a density of crystalline defects can be modelled by a fibre bundle, whose fibres are
            interpreted as microscopic space inside the macroscopic points of the base, with a metric and an affine
            connection. A so-called Riemann-Cartan manifold. The study of the kinematics of deformations in these media
            was started by
            &nbsp;<a
            about={"ORCID page of Dr. Nguyen"}
            href={"https://orcid.org/0000-0002-1663-2817"}>
            Van Hoi Nguyen </a>
            in his thesis directed by Pr. Le Marrec and Dr. Casale.<br/>
            <br/>
            The present thesis aimed at improving that kinematic description and further developing the model. By
            carefully analysing the physical interpretation of every object present in the theory, we arrived at the
            conclusion that the existence of a metric on the total space was not physically. Instead, a pseudo-metric,
            generalising in a way the Cauchy-Green tensor, was more fitting. Its kernel, as it turns out, corresponds to
            the horizontal space of the no-slip connection, obtained as (the pull-back of) the difference of the
            Levi-Civita connection and the canonical solder form. For more information, please read the corresponding
            conference proceeding:
            &nbsp;<i><a href={"https://hal.science/hal-04364016"}>Continuum mechanics of defective media: an approach
            using fiber bundles</a></i>.<br/>
            <br/>
            Further investigation of the physical interpretation, in particular the notion of frame indifference (or
            "objectivity") led to a formal notion of generalised change of frames, generalised frame indifference and,
            ultimately, generalised strain measures. In particular, in the holonomic micro-linear case, the result
            simply state that a functional of the generalised placement map is frame indifferent (in the generalised
            sens) if and only if it is expressible as a functional of the material pseudo-metric (i.e. the generalised
            Cauchy-Green tensor). For more information, please read the corresponding preprint
            &nbsp;<i><a href={"https://hal.science/hal-04527229"}>Two-Scale Geometric Modelling for Defective
            Media</a></i>.
        </div>
    }];

items.reverse()

export default items;