export const VCARD = `BEGIN:VCARD
VERSION:2.1
N;CHARSET=UTF-8:Crespo;Mewen
FN;CHARSET=UTF-8:Mewen Crespo
TEL;HOME;VOICE:+33699076112
ORG;CHARSET=UTF-8:University of Rennes — IRMAR
TITLE;CHARSET=UTF-8:PhD. Student
ADR;CHARSET=UTF-8;WORK;PREF:;;Université de Rennes - Campus Beaulieu\\, Bâtiment 22\\, bureau 212\\, 263 Av. Général Leclerc;Rennes;35000;France
EMAIL:mcrespopro+qr@gmail.com
URL:https://fr.linkedin.com/in/mewen-crespo
END:VCARD`

export default VCARD;