// src/components/NotFound.js
import React, { useState } from 'react';
import './NotFound.css';
import MainPage from './MainPage';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const [isVisible, setIsVisible] = useState(true);

    const navigate = useNavigate();

    const handleClose = () => {
        setIsVisible(false);
        setTimeout(() => {navigate('/')}, 300)
    };

    return (
        <div className="main-wrapper">
            <MainPage />
            <div className={`not-found-overlay ${isVisible ? 'visible' : 'hidden'}`}>
                <div className="not-found-container">
                    <h1 className="not-found-title">404</h1>
                    <p className="not-found-message">Page Not Found</p>
                    <button className="not-found-link" onClick={handleClose}>
                        Click Here To Go Home
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
