import React from 'react';
import './EduPage.css';

import events from './data/Edu'
import EventCard from "../components/EventCard";

const EventsList = () => (
    <div className="events-container">
        {events.map((event, index) => (
            <EventCard event={event}/>
        ))}
    </div>
);

export default EventsList;
