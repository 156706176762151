export const languages = [
    {
        name: 'Scala',
        logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Scala-full-color.svg/121px-Scala-full-color.svg.png',
        proficiency: 100
    },
    {
        name: 'Java',
        logo: 'https://upload.wikimedia.org/wikipedia/en/thumb/3/30/Java_programming_language_logo.svg/121px-Java_programming_language_logo.svg.png',
        proficiency: 90
    },
    {
        name: 'C/C++',
        logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/120px-ISO_C%2B%2B_Logo.svg.png',
        proficiency: 60
    },
    {
        name: 'JavaScript',
        logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/600px-JavaScript-logo.png',
        proficiency: 85
    },
    {
        name: 'Python',
        logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/121px-Python-logo-notext.svg.png',
        proficiency: 100
    },
    {
        name: 'Lua',
        logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Lua-Logo.svg/128px-Lua-Logo.svg.png',
        proficiency: 100
    },
    {
        name: 'MatLab',
        logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Matlab_Logo.png/121px-Matlab_Logo.png',
        proficiency: 50
    },
    // Add more languages as needed
];

export default languages;