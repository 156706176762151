import OptLink from "./OptLink";
import React, {useEffect, useRef, useState} from "react";
import './EventCard.css';
import {tryIndex} from "../Utils";

const Credit = ({event}) => {
    if (event.image === undefined) return undefined;
    if (event.credit && event.credit.length > 0) {
        const credits = [...event.credit];
        if (event.image.credit) credits.push(Object.assign({title: "image"}, event.image.credit));
        return (<p className={"event-credit-title"}>
            <p className={"event-credit-title"}>Credits:</p>
            <ul className={"event-credit-list"}>
                {credits.map((credit) => {
                    if (credit.url) return <li>{credit.title + ": "}<a href={credit.url}>{credit.text}</a></li>
                    return <li>{credit.title + ": " + credit.text}</li>
                })}
            </ul>
        </p>);
    } else if (event.image.credit) {
        if (event.image.credit.url) return (<p className={"event-credit"}>
            image's credit: <a href={event.image.credit.url}>{event.image.credit.text}</a>
        </p>);
        return (<p className={"event-credit"}>
            image's credit: {event.image.credit.text}
        </p>);
    }
}

export const EventCard = ({event}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const descriptionRef = useRef(null);

    useEffect(() => {
        const checkOverflow = () => {
            const hasOverflow = descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight;
            setIsOverflowing(hasOverflow);
        };
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, [descriptionRef])

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const image_alt = tryIndex(event, ["image", "description", "label"], ["image", "description", "caption"], ["title"])

    return (<div className="event-card">
        <OptLink href={event.link}><img className={"event-img"} src={event.image && event.image.content}
                                        alt={image_alt}/></OptLink>
        <div className={`event-content ${isExpanded ? 'expanded' : ''}`} ref={descriptionRef}>
            <OptLink href={event.link}><h3 className="event-title">{event.title}</h3></OptLink>
            <p className="event-date">{event.date + " — " + event.duration}</p>
            <OptLink href={event.location_link}><p className="event-location">{event.location}</p></OptLink>
            <p className="event-description">{event.description}</p>
            <Credit event={event}/>
            {(event.image && event.image.description)
                && <div><p className={"event-caption-title"}>image's caption:</p><p
                    className={"event-caption-content"}>{event.image.description.caption}</p></div>}
        </div>
        {isOverflowing && (<button className="toggle-button" onClick={handleToggle}>
            {isExpanded ? 'Show less' : 'Show more'}
        </button>)}
    </div>);
}

export default EventCard;