import React from "react";
import config from "../Config";
/*import diapason from "../../rsrc/diapason.jpeg"
// noinspection SpellCheckingInspection
import istic from "../../rsrc/istic.jpg"
// noinspection SpellCheckingInspection
import duguesclin from "../../rsrc/duguesclin2.jpeg";
// noinspection SpellCheckingInspection
import experimentarium from "../../rsrc/experimentarium.jpeg"
// noinspection SpellCheckingInspection
import tfjm from "../../rsrc/tfjm.png"
// noinspection SpellCheckingInspection
import mathc2_2023 from "../../rsrc/mathc2_2023_540p.jpg"

const diapason_credit = undefined
// noinspection SpellCheckingInspection
const istic_credit = undefined
// noinspection SpellCheckingInspection
const duguesclin_credit = undefined
// noinspection SpellCheckingInspection
const experimentarium_credit = undefined
// noinspection SpellCheckingInspection
const tfjm_credit = undefined
// noinspection SpellCheckingInspection
const mathc2_2023_credit = undefined*/

import {inventory} from '../../rsrc/inventory'

const items = [{
    title: "Lectures", date: "2022 / 2023", duration: "64 hours", location: "University of Rennes", description: <div>
        <ul>
            <li><h4>Linear Algebra and Gaussian Method</h4></li>
            36 hours for students in their first year of a Bachelor's degree in computer science
            <li><h4>Probabilities and Differential Equations</h4></li>
            18 hours for students in their first year of a Bachelor's degree in biology
            <li><h4>Physics and Mechanics of the points</h4></li>
            10 hours for students in their first year of a Bachelor's degree in mathematics
        </ul>
    </div>, image: inventory["diapason.jpeg"], released: true
}, {
    title: "Lectures",
    date: "2023 / 2024",
    duration: "64 hours",
    location: "University of Rennes",
    image: inventory["istic.jpg"],
    description: <div>
        <h4>Basis of imperative programming in JAVA</h4>
        64 hours for students in their first year of a Bachelor's degree in computer science.
        <br/>
        <br/>
        The topics covered include variables, conditions, loops, arrays, functions, recurcivity and a final project
        on a graph-based modelling of social networks
    </div>
}, {
    title: "Primary School Intervention",
    date: "July 4, 2023",
    duration: "4 hours",
    location: "Ecole primaire publique du Guesclin",
    location_link: "https://www.education.gouv.fr/annuaire/35300/fougeres/ecole/0351861h/ecole-primaire-publique-du-guesclin.html",
    image: inventory["duguesclin2.jpeg"],
    description: <div>
        <h4>Soap films and topological optimisations</h4>
        In this short intervention, composed of 8 repetitions of the same 30 minutes long animation, we investigated
        the behaviour of soap films. First, in front of the pupils, we immersed a closed ellipsoidal curve in the
        soap and looked at the resulting form taken by the film. Then, we let them experiment with closed loops of
        their choosing. We then showed them the result of immersing a tetrahedron.
        <br/><br/>
        Based on this last observation and after a short explanation on the fact that films take the smallest
        surface area, we asked them to find, by hand, the smallest line connecting the vertex of a triangle, and
        then a rectangle. We compared the results, gave the solution and then concluded by showing the three
        possible resulting films for a cube.
    </div>
}, {
    title: "'Expérimentarium' at Saint-Grégoire",
    date: "December 1 and 2, 2023",
    duration: "One afternoon, roughly 4 hours",
    location: "Espace du Roi Arthur, Saint-Grégoire",
    location_link: "https://maps.app.goo.gl/c5uh2LurgCoyWnwE7",
    image: inventory["experimentarium.jpeg"],
    description: <div>
        This 'experimentarium', an initiative of the local science association, covered several fields of
        science. The targeted public was mostly young children, but everyone was welcomed (and present). On the
        maths stand, the topics included, but were not limited to:
        <ul>
            <li>Soap films manipulation and the notion of surface minimisation</li>
            <li>Regular polygons manipulation and Platonic solids</li>
            <li>Weird crocheted non-planar forms and the notion of curvature</li>
            <li>Periodic and aperiodic tilings and Penrose tiling</li>
            <li>Magic number trick and the Hamming code</li>
        </ul>

        The activity led to an article in the local press !
        <br/>
        <br/>
        <a target="_blank" rel="noopener noreferrer"
           href={"https://www.ouest-france.fr/bretagne/saint-gregoire-35760/une-journee-dexplorations-scientifiques-a-saint-gregoire-944b5414-91ea-11ee-8dbe-6f5de331dde4"}>
            ➔ Link to the online version of the article
        </a>
        <br/>
        <br/>
        <a target="_blank" rel="noopener noreferrer"
           href={"https://irmar.univ-rennes.fr/actualites/diffusion-des-mathematiques"}>
            ➔ Link to a picture of the printed version
        </a>

    </div>,
    link: "https://www.ouest-france.fr/bretagne/saint-gregoire-35760/une-journee-dexplorations-scientifiques-a-saint-gregoire-944b5414-91ea-11ee-8dbe-6f5de331dde4"
}, {
    title: "Tournoi Français des Jeunes Mathématiciennes et Mathématiciens 2023",
    date: "April 15 and 16, 2023",
    duration: "A week-end, roughly 12 hours",
    location: "ENS Rennes",
    location_link: "https://www.ens-rennes.fr",
    image: inventory["tfjm.png"],
    description: <div>
        The Tournoi Français des Jeunes Mathématiciennes et Mathématiciens is a tournament for secondary school
        students. It differs from other mathematics competitions in that it proposes research problems that
        require collective thinking (in teams of four to six students with one or two supervisors) over a long
        period of time (several months). The problems have no known complete solution. The participants are in
        the position of researchers. This is an opportunity to make initial contact with the world of research.
        <br/>
        <br/>
        I was a member of the jury for the Rennes tournament (one of 9 tournaments in France). The groups are
        evaluated based on their performances in three stages
        <ul>
            <li>Defender</li>
            where one member of the group presents the results on a given subject.

            <li>Opponent</li>
            where one member of the group presents their critics with respect to the presentation of another
            group and asks questions to them in order to start a small debate.

            <li>Reporter</li>
            where one member of the group presents their critics with respect to overall interaction between
            another defender and opponent.
        </ul>

        Although the context was competitive, the atmosphere was very relaxed and nobody was really into pure
        competition. The students seemed to have had a wonderful time, both scientifically and socially.
    </div>,
    link: "https://tfjm.org"
}, {
    title: "MathC2+ 2023",
    date: "June 12 to 16, 2023",
    duration: "A week, roughly 15 hours",
    location: "INSA Rennes",
    location_link: "https://www.insa-rennes.fr/index.html",
    image: inventory["mathc2_2023_540p.jpg"],
    description: <div>
        MathC2+ is a French educational program organized, for the edition of Rennes, by
        the <a href={"https://www.ens-rennes.fr/"}>ENS Rennes</a> at
        the <a href={"https://www.insa-rennes.fr/index.html"}>INSA Rennes</a>.
        The aim of MathC2+ is to encourage high school students to pursue studies in mathematics and
        related fields by engaging them in various fun and educational activities.
        <br/><br/>
        These activities are designed to showcase the diversity of careers that mathematics can lead to and to
        make mathematics and computer science appealing to young students. The program typically includes
        workshops, lectures, and hands-on projects, offering students a chance to explore mathematical concepts
        and their applications in an engaging and supportive environment.
        <br/><br/>
        TODO
    </div>,
    link: "https://www.mathc2plus.fr",
    released: false
}, {
    title: "Math Club", date: "June 12 to 16, 2023", //TODO
    duration: "A week, roughly 15 hours", //TODO
    location: "INSA Rennes", //TODO
    location_link: "https://www.insa-rennes.fr/index.html", //TODO
    image: inventory["istic.jpg"], //TODO
    description: <div>
        TODO
    </div>, link: "https://www.mathc2plus.fr", released: false
}, {
    title: "Short internship for secondary school pupils", date: "June 12 to 16, 2023", //TODO
    duration: "A week, roughly 15 hours", //TODO
    location: "INSA Rennes", //TODO
    location_link: "https://www.insa-rennes.fr/index.html", //TODO
    image: inventory["istic.jpg"], //TODO
    description: <div>
        TODO
    </div>, link: "https://www.mathc2plus.fr", released: false
}, {
    title: "Tournoi Français des Jeunes Mathématiciennes et Mathématiciens 2024", date: "June 12 to 16, 2023", //TODO
    duration: "A week, roughly 15 hours", //TODO
    location: "INSA Rennes", //TODO
    location_link: "https://www.insa-rennes.fr/index.html", //TODO
    image: inventory["istic.jpg"], //TODO
    description: <div>
        TODO
    </div>, link: "https://www.mathc2plus.fr", released: false
}, {
    title: "MathC2+ 2024", //TODO
    date: "June 12 to 16, 2023", //TODO
    duration: "A week, roughly 15 hours", //TODO
    location: "INSA Rennes", //TODO
    location_link: "https://www.insa-rennes.fr/index.html", //TODO
    image: inventory["istic.jpg"], //TODO
    description: <div>
        TODO
    </div>, link: "https://www.mathc2plus.fr", released: false
}, {
    title: "Short internship for high school students", date: "June 12 to 16, 2023", //TODO
    duration: "A week, roughly 15 hours", //TODO
    location: "INSA Rennes", //TODO
    location_link: "https://www.insa-rennes.fr/index.html", //TODO
    image: inventory["istic.jpg"], //TODO
    description: <div>
        TODO
    </div>, link: "https://www.mathc2plus.fr", released: false
}, {
    title: "Example", //TODO
    date: "June 12 to 16, 2023", //TODO
    duration: "A week, roughly 15 hours", //TODO
    location: "INSA Rennes", //TODO
    location_link: "https://www.insa-rennes.fr/index.html", //TODO
    image: inventory["istic.jpg"], //TODO
    description: <div>
        TODO
    </div>, link: "https://www.mathc2plus.fr", released: false, hide: true
}];

items.reverse()

export default items.filter((pub) => (pub.released === undefined) || pub.released || (!config.in_production && !pub.hide));