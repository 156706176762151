import React from "react";

export const aboutMe = <p className="section-content">
    <div>
        Born in Vannes, France, where I studied until high school, I moved to Rennes where I passed my Bachelor's degree
        and Master's degree in mathematics and then started my PhD thesis. I was always passionate about all sciences,
        as my Curriculum-Vitae can attest: in addition to my mathematical degrees, I have another Bachelor's degree in
        computer science, a master's degree in cryptography, an online MIT certificate in quantum mechanics and two
        internships in engineering applied to biology.
    </div>
    <br/><br/>
    <div>
        I also love teaching: I taught mathematics and computer science for a total of 128 hours during my Ph.D. (the
        maximum time allowed) and took pride in accepting every science communication my colleagues had to offer. These
        ranged from primary schools experiments and public interventions for all ages to high school competition juror
        and one week high school internships supervisor.
    </div>
</p>

export default aboutMe;