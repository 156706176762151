// noinspection JSUnresolvedLibraryURL

import React from 'react';
import './MainPage.css';
import LanguageList from '../components/LanguageList.js';
import PublicationCard from "../components/PublicationCard";
import publications from "./data/Publications";
import languages from "./data/LanguagesProficiencies";
import aboutMe from "./data/AboutMe";
import links from "./data/Links";
import VCARD from "./data/VCARD";
import {inventory} from '../rsrc/inventory'

const pp_files = [
    //"https://media.licdn.com/dms/image/D5603AQFIl7GzE52oXQ/profile-displayphoto-shrink_200_200/0/1669992215436?e=2147483647&v=beta&t=VdwBUWFtfTjnTXZLCgD7L3OfVqr907VmpI33CJpw18I",
    inventory["profile_picture_0.jpg"],
    inventory["profile_picture_1.jpg"],
    inventory["profile_picture_2.jpg"],
    inventory["profile_picture_3.jpg"],
    inventory["profile_picture_4.jpg"]
]

const chosen_pp = pp_files[2-Math.round(Math.random()**3)];

const profile_picture =
    <div class="profile-pic-crop">
        <img
        src={chosen_pp.content}
        width={chosen_pp.width || "auto"}
        style={{margin: chosen_pp.margin || "0"}}
        className="profile-pic-img" alt={"Mewen Crespo"}/>
    </div>

const LinksDiv = <div className="section">
    <h2 className="section-title">Links</h2>
    <div className="links">
        <center>
            {links.map((link, index) => (
                <a href={link.url}
                   target="_blank" rel="noopener noreferrer">{link.title}</a>
            ))}
        </center>
    </div>
</div>

const ContactDiv = <div className="side-by-side-container">
    <div className="side-by-side-item">
        <div className="contact-info">
            <h2>Contact Information</h2>
            <div className="info-item">
                <span>Email:</span>
                <span><a href="mailto:mewen.crespo@univ-rennes.fr">mewen.crespo@univ-rennes.fr</a></span>
            </div>
            <div className="info-item">
                <span>Laboratory:</span>
                <span> <a href={"https://irmar.univ-rennes.fr"}>IRMAR</a>, University of Rennes</span>
            </div>
            <div className="info-item">
                <span>Address:</span>
                <span>263 Av. Général Leclerc,<br/> 35000 Rennes, France</span>
            </div>
            <div className="info-item">
                <span>Office:</span>
                <span>building 22, room 212</span>
            </div>
        </div>
    </div>
    <div className="side-by-side-item">
        <h2 className="section-title">Or simply scan (or click) me!</h2>
        <a href={"data:text/vcard," + VCARD} download="mewen_crespo.vcard">
            <img src={"https://qr-codes-svg.s3.amazonaws.com/eQavj8.svg?1710365538645"}
                 alt={"QR code containing a vcard"}
                 width="255px" height="auto"/>
        </a>
    </div>
</div>

const InterestDiv = <div className="side-by-side-container">
    <div className="side-by-side-item">
        <h2 className="section-title">Research Interests</h2>
        <ul className="interests-list">
            <li>Generalized continua</li>
            <li>Defective media</li>
            <li>Fibre bundle theory</li>
            <li>Connections</li>
        </ul>
    </div>
    <div className="side-by-side-item">
        <h2 className="section-title">Hobbies</h2>
        <ul className="hobbies-list">
            <li>Custom compiler/transpiler development</li>
            <li>Physical and graphical engine</li>
            <li>Music (great pipe organ)</li>
            <li>Role-playing games and table top games</li>
        </ul>
    </div>
</div>

const MainPage = () => {
    const last_pub = (publications.length > 0) && publications[0];

    return (<html>
    <head>
        <link
            href="//cdnjs.cloudflare.com/ajax/libs/KaTeX/0.9.0/katex.min.css"
            rel="stylesheet"
        />
        <title>Mewen Crespo - Home</title>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
    </head>
    <body className="main-page">
    <header className="header">
        {profile_picture}
        <h1 className="name">Ph.D. Mewen Crespo</h1>
    </header>
    <div className="content">
        <div className="section">
            <h2 className="section-title">About Me</h2>
            {aboutMe}
        </div>

        {InterestDiv}

        {ContactDiv}

        {LinksDiv}

    </div>

    <br/>

    <div className="content">
        <div>
            <h1>Programming Language Proficiency</h1>
            <LanguageList languages={languages}/>
        </div>
    </div>

    {last_pub && <div>
        <h1>Last publication</h1>
        <PublicationCard
            key={0}
            title={last_pub.title}
            date={last_pub.date}
            type={last_pub.type}
            url={last_pub.url}
            externalIds={last_pub.externalIds}
            contributors={last_pub.contributors}
            abstract={last_pub.abstract}
        />
    </div>}
    </body>
    </html>);
};

export default MainPage;
