import React from "react";

export const OptLink = ({href, children}) => {
    if (href) {
        return <a href={href} target="_blank" rel="noopener noreferrer">
            {children}
        </a>
    }
    return children;
}

export default OptLink;