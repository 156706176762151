// import LI from "../../components/LoremIpsum";

import config from "../Config";

const publications = [
    {
        title: "Two-Scale Geometric Modelling for Defective Media",
        date: "March 2024",
        type: "Preprint",
        url: "https://hal.science/hal-04527229",
        externalIds: [
            {
                kind: "HAL",
                id: "04527229"
            },
            {
                kind: "arXiv",
                id: "2404.03269"
            }],
        contributors: ["Mewen Crespo", "Guy Casale", "Loïc Le Marrec"],
        abstract: `A new geometrically exact micro-structured model is constructed using a generalisation of the
            notion of Riemann-Cartan manifolds and fibre bundle theory of rank $3$. This models is based
            around the concept of two different length scales: a macroscopic scale − of dimensions $1$, $2$, or $3$ $−$
            and a microscopic one $−$ of dimension $3$. As they interact with each other, they produce emergent
            behaviours such as dislocations (torsion) and disclinations (curvature). A first-order placement
            map $\\mathbf{F}: \\mathrm{T}\\mathcal{B} \\longrightarrow \\mathrm{T}\\mathcal{E}$ between a micro-structured
            body $\\mathcal{B}$ and the micro-structured ambient space $\\mathcal{E}$ is constructed, allowing to pull the ambient
            Riemann-Cartan geometry back onto the body. In order to allow for curvature to arise, $\\mathbf{F}$ is, in general,
            not required to be a gradient. Central to this model is the new notion of pseudo-metric, providing, in addition to a
            macroscopic metric (the usual Cauchy-Green tensor) and a microscopic metric, a notion of coupling between the
            microscopic and macroscopic realms. A notion of frame indifference is formalised and invariants are computed. In
            the case of a micro-linear structure, it is shown that the data of these invariants is equivalent to
            the data of the pseudo-metric.`,
        released: true
    },


    {
        title: "Continuum Mechanics of Defective Media: An Approach Using Fiber Bundles",
        date: "August 2023",
        type: "Proceeding",
        url: "https://doi.org/10.1007/978-3-031-38299-4_5",
        externalIds: [
            {
                kind: "DOI",
                id: "10.1007/978-3-031-38299-4_5"
            }],
        contributors: ["Mewen Crespo", "Guy Casale", "Loïc Le Marrec"],
        abstract: `The kinematics of a micro-structured material is geometrically modeled through the framework of fiber
            bundle geometry. The material continuum is a fiber bundle $\\mathcal{M} \\to \\mathbb{B}$ where
            $\\mathbb{B}$ is compact and orientable. It is commonly agreed that connections with curvature and torsion
            can describe defect densities in  micro-structured materials. The aim of this work is to introduce a method
            to derive these objects from the kinematics in an intrinsic way. The material bundle $\\mathcal{M}$ is
            therefore placed in the Euclidean fiber bundle $\\mathcal{E}\\equiv\\mathrm{T}\\mathbb{E}\\to\\mathbb{E}$
            using a placement map $\\varphi: \\mathcal{M} \\to \\mathcal{E}$. A first-order transformation $\\mathbf{F}:
            \\mathrm{T}\\mathcal{M}\\to\\mathrm{T}\\mathcal{E}$ generalizing $\\mathrm{T}\\varphi$ is then introduced.
            Finally, using $\\mathbf{F}$, a metric on $\\mathbb{B}$, a connection on $\\mathcal{M}$ and a solder form on
            $\\mathcal{M}$ are inferred from the Euclidean structure on $\\mathcal{E}$. These new objects allow us to
            describe the current material state via the curvature (disclinations) and torsion (dislocations) tensors.
            On one hand, we see that the torsion tensor can be non-zero even in the holonomic
            $\\mathbf{F}=\\mathrm{T}\\varphi$ case. On the other-hand, in order for the material to have a
            non-zero curvature tensor, we see that one must have a non-holonomic first-order transformation: that is,
            $\\mathbf{F}\\hspace{0.2em}\\mathop{\\mathrlap/{\\hspace{-0.15em}=}}\\hspace{0.1em}\\mathrm{T}\\varphi$.`,
        released: true
    }
    // Add more publications as needed
];

export default publications.filter((pub, id, arr) =>
    (pub.released === undefined) || pub.released || !config.in_production);