import React, {useState} from 'react';
import './PublicationCard.css';
import Latex from 'react-latex'

function urlFormId(kind, id) {
    switch(kind.toUpperCase()) {
        case "DOI":
            return "https://doi.org/" + id
        case "HAL":
            if (id.startsWith("hal-")) {
                return "https://hal.science/" + id
            }
            return "https://hal.science/hal-" + id
        case "ARXIV":
            return "https://arxiv.org/abs/" + id
        default:
            return id
    }
}

const PublicationCard = ({title, date, type, url, externalIds /*: [{kind, id, url}]*/, abstract, contributors}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="publication-card">
            <div className="header">
                <h4 className="work-title"><Latex>{title}</Latex></h4>
            </div>
            <div className="body">
                <div className="general-data">
                    <span className="date">{date}</span>
                    <span className="separator">|</span>
                    <span className="type">{type}</span>
                </div>
                {externalIds.map((externalId, _) => (
                    <div className="external-id">
                        <span className="id-label">{externalId.kind}:&nbsp;</span>
                        <a href={urlFormId(externalId.kind, externalId.id)}
                           target="_blank"
                           rel="noopener noreferrer" className="id-link">{externalId.id}</a>
                    </div>
                ))}
                <div className="contributors">
                    <span className="contributors-label uppercase">Authors</span>: {contributors.join('; ')}
                </div>
                <div className="abstract-container">
                    <p className={`abstract-text ${isExpanded ? 'expanded' : ''}`}><Latex>{abstract}</Latex></p>
                    {!isExpanded && <div className="fade-effect"></div>}
                </div>
                <button className="toggle-button" onClick={handleToggle}>
                    {isExpanded ? 'Show less' : 'Show more'}
                </button>
                <div className="show-more">
                    <a href={url} target="_blank" rel="noopener noreferrer" role="button" tabIndex="0"
                       className="show-more-link">{`Get more information about this paper on ${url}`}</a>
                </div>
            </div>
        </div>
    );
};

export default PublicationCard;
