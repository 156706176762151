const config = {
    in_production: true,
    pages: [
        {
            name: 'Page Not Found',
            url_path: '*',
            js_path: 'pages/NotFound',
            released: true,
            not_in_menu : true
        },
        {
            name: 'Home',
            url_path: '/',
            js_path: 'pages/MainPage',
            released: true
        },
        {
            name: 'Curriculum-Vitae',
            url_path: '/cv',
            js_path: 'pages/CVPage',
            released: true
        },
        {
            name: 'Teaching',
            url_path: '/teaching',
            js_path: 'pages/EduPage',
            released: true
        },
        {
            name: 'Publications',
            url_path: '/publications',
            js_path: 'pages/PublicationsPage',
            released: true
        },
        {
            name: 'Test Page',
            url_path: '/test',
            js_path: 'pages/TestPage',
            released: false
        }
    ]
}

export default config;