import React from 'react';
import {Chrono} from 'react-chrono';
import './CVPage.css';

import data from "./data/CV";


function CVPage() {
    return (
        <div className="cv-page">
            <h1>Career Timeline</h1>
            <Chrono
                items={data}
                mode="VERTICAL_ALTERNATING"
                // theme={{primary: "#000", secondary: "#fff", cardBgColor: "#000", cardForeColor: "#fff" }}
                borderLessCards
                // enableDarkToggle
                enableLayoutSwitch={false}
                focusActiveItemOnLoad
                // highlightCardsOnHover
                timelinePointShape={"diamond"}
                // useReadMore={false}
                disableToolbar
                disableInteraction
                theme={{
                    cardBgColor: '#ECE9EB',
                    primary: '#60A4A9',
                    titleColor: '#366063',
                    titleColorActive: '#366063',
                    secondary: '#FFFFFFFF',
                }}
            />
        </div>
    );
}

export default CVPage;
